var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Judul")])]), _c('p', [_vm._v(_vm._s(_vm.pb.judul))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', [_vm._v(_vm._s(_vm.humanDate(_vm.pb.tanggal)))])]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.pb.keterangan ? _vm.pb.keterangan : "-"))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Total")])]), _c('p', [_vm._v("Rp. " + _vm._s(_vm.pb.total ? _vm.formatRupiah(_vm.pb.total) : "-"))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status Finance")])]), _c('p', [_vm.pb.id_akun == null || _vm.pb.id_akun == 0 ? _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v("Belum Pilih Akun")]) : _vm._e()], 1), _c('p', [_vm.pb.id_akun > 0 ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v("Sudah Pilih Akun")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_vm.isFinance && _vm.pb.selesai == 0 && (_vm.pb.id_akun == null || _vm.pb.id_akun == 0) ? _c('b-button', {
    attrs: {
      "disabled": _vm.items.length < 1,
      "size": "sm",
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmdiberikan();
      }
    }
  }, [_vm._v("Pilih Sumber Pengeluaran")]) : _vm._e(), _vm.isFinance && _vm.pb.selesai == 1 && _vm.pb.id_akun > 0 ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmcanceljurnal();
      }
    }
  }, [_vm._v("Cancel Akun")]) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-select2",
      "title": "Pilih Akun dan Kas untuk Biaya Pajak",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": !_vm.isValidAkunForm,
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.diberikan($event);
            }
          }
        }, [_vm._v(" Simpan ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalapprfinance,
      callback: function ($$v) {
        _vm.showModalapprfinance = $$v;
      },
      expression: "showModalapprfinance"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Nominal Biaya"
    }
  }, [_c('h4', [_c('i', {
    staticClass: "text-success"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.pb.total)))])])])]), _c('b-form-group', {
    attrs: {
      "label": "Pilih Akun",
      "label-for": "vue-select-akun"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-1",
    attrs: {
      "id": "vue-select-akun",
      "options": _vm.id_akun
    },
    model: {
      value: _vm.form2.id_akun,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "id_akun", $$v);
      },
      expression: "form2.id_akun"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kas",
      "label-for": "vue-select"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-1",
    attrs: {
      "id": "vue-select",
      "options": _vm.id_kas
    },
    model: {
      value: _vm.form2.id_kas,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "id_kas", $$v);
      },
      expression: "form2.id_kas"
    }
  }), _vm.invalidKas ? _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Saldo Kas kurang dari nominal biaya")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('main', [_c('b-card', {
    attrs: {
      "action-collapse": "",
      "title": "Pajak"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1"
  }, [_vm.pb.selesai == 0 ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Rincian Ajuan ")], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form "
    },
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formbiaya_pajak"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Item Pajak",
      "label-for": "v-item"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-item",
      "placeholder": "Ex: PPN"
    },
    model: {
      value: _vm.form.item,
      callback: function ($$v) {
        _vm.$set(_vm.form, "item", $$v);
      },
      expression: "form.item"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kuantitas",
      "label-for": "v-qty"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "v-qty",
      "type": "number",
      "placeholder": "Isi kuantitas"
    },
    model: {
      value: _vm.form.qty,
      callback: function ($$v) {
        _vm.$set(_vm.form, "qty", $$v);
      },
      expression: "form.qty"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Biaya",
      "label-for": "v-biaya"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    staticClass: "form-control",
    on: {
      "keyup": _vm.doFormatRupiah
    },
    model: {
      value: _vm.form.biaya,
      callback: function ($$v) {
        _vm.$set(_vm.form, "biaya", $$v);
      },
      expression: "form.biaya"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(biaya)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(item.biaya)) + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(item.qty * item.biaya)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.pb.diterima == null && _vm.pb.selesai == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.pb.diterima == null && _vm.pb.selesai == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }